<template>
	<div class="domesticDebt">
		<!-- <div class="tabBox">
			<Tabs v-model="tab">
				<TabPane label="境内债" name="tab1"></TabPane>

				<TabPane label="项目进度" name="tab3"></TabPane>
			</Tabs>
		</div> -->
		<div class="topPart" v-if="tab !== 'tab3'">
			<div class="form-item">
				<!-- <div class="inputBox">
          <div class="title">债券全称</div>
          <Input
            v-model="params.zqqc"
            placeholder="模糊搜索"
            style="margin-right: 50px; width: 300px"
          />
        </div> -->
				<div class="inputBox">
					<div class="title">债券简称</div>
					<Input v-model="params.zqjc" placeholder="模糊搜索" style="margin-right: 50px; width: 300px" />
				</div>
				<div class="inputBox">
					<div class="title">债券代码</div>
					<Input v-model="params.zqdm" placeholder="模糊搜索" style="width: 300px" />
				</div>
			</div>
			<div class="form-item">
				<span class="title">到期日期</span>
				<DateRange v-model="params.dqrq" @change="(value) => this.handleChangeOption('到期时间', 'date', 'dqrq', value)">
				</DateRange>
				<span class="title">发行日期</span>
				<DateRange v-model="params.fxrq" @change="(value) => this.handleChangeOption('发行日期', 'date', 'fxrq', value)">
				</DateRange>
				<span class="title">起息日期</span>
				<DateRange v-model="params.qxrq" @change="(value) => this.handleChangeOption('起息日期', 'date', 'qxrq', value)">
				</DateRange>
			</div>

			<!-- <div class="form-item">
				<span class="title">到期日期</span>
				<DatePicker :type="'date'" v-model="params.dqrq[0]" format="yyyy-MM-dd" @on-change="getTimeStartdq"
					placeholder="开始时间" style="width: 140px; margin-right: 5px"></DatePicker>至
				<DatePicker :type="'date'" v-model="params.dqrq[1]" format="yyyy-MM-dd" @on-change="getTimeEnddq"
					placeholder="结束时间" style="width: 140px; margin: 0 50px 0 5px"></DatePicker>
				<span class="title">发行日期</span>
				<DatePicker :type="'date'" v-model="params.fxrq[0]" @on-change="getTimeStartfx" format="yyyy-MM-dd"
					placeholder="开始时间" style="width: 140px; margin-right: 5px"></DatePicker>至
				<DatePicker :type="'date'" v-model="params.fxrq[1]" :picker-options="pickerOptions" placeholder="结束时间"
					@on-change="getTimeEndfx" format="yyyy-MM-dd" style="width: 140px; margin: 0 50px 0 5px">
				</DatePicker>
				<span class="title">起息日期</span>
				<DatePicker :type="'date'" v-model="params.qxrq[0]" format="yyyy-MM-dd" @on-change="getTimeStartqx"
					placeholder="开始时间" style="width: 140px; margin-right: 5px"></DatePicker>至
				<DatePicker :type="'date'" v-model="params.qxrq[1]" format="yyyy-MM-dd" @on-change="getTimeEndqx"
					placeholder="结束时间" style="width: 140px; margin: 0 50px 0 5px"></DatePicker>
			</div> -->
			<div class="form-item">
				<div class="inputBox">
					<div class="title">剩余期限</div>

					<Select v-model="params.dqqk[0]" clearable style="width: 200px; margin-right: 20px">
						<Option v-for="item in syqxList" :value="item.id" :key="item.name">{{ item.name }}</Option>
					</Select>
				</div>
				<div class="inputBox">
					<div class="title">是否违约</div>
					<Select v-model="params.wyqk" clearable style="width: 300px; margin-right: 20px">
						<Option v-for="item in breakList" :value="item.value" :key="item.value">{{ item.label }}
						</Option>
					</Select>
				</div>

			</div>

			<div class="form-item">
				<div class="title">债券市场</div>
				<CheckboxGroup v-model="params.zqsc">
					<Checkbox v-for="(item, index) in zqscList" :key="index" :label="item.name"></Checkbox>
					<!-- <Checkbox label="银行间"></Checkbox>
        <Checkbox label="深交所"></Checkbox>
                    <Checkbox label="上交所"></Checkbox>-->
					<Checkbox label="柜台市场" disabled></Checkbox>
					<Checkbox label="区域股权交易中心" disabled></Checkbox>
					<Checkbox label="其他市场" disabled></Checkbox>
				</CheckboxGroup>
			</div>

			<div class="form-item">
				<div class="title">债券类型</div>
				<Select v-model="params.zqlx" clearable filterable placeholder="大分类" @on-change="getzqlx"
					style="width: 300px; margin-right: 20px">
					<Option v-for="item in zqlxzdhList" :value="item.id" :key="item.id">{{
						item.name
					}}</Option>
				</Select>
				<Select v-model="params.zqlxy" clearable placeholder="细分类型" filterable style="width: 300px">
					<Option v-for="item in zqlxList" :value="item.id" :key="item.id">{{
						item.name
					}}</Option>
				</Select>

				<div style="margin-left: 10px;" class="title">行业分类</div>
				<Select clearable v-model="params.hyhf" style="width: 300px">
					<Option v-for="item in categoryList" :value="item.code" :key="item.code">{{ item.name }}
					</Option>
				</Select>


			</div>

			<div class="form-item">
				<div class="inputBox">
					<div class="title">发行人/受托机构</div>
					<Input v-model="params.stjg" placeholder="模糊搜索" style="margin-right: 50px; width: 300px" />
				</div>
				<div class="inputBox">
					<div class="title">原始权益人</div>
					<Input v-model="params.ysqyr" placeholder="模糊搜索" style="margin-right: 50px; width: 300px" />
				</div>
			</div>




			<Button type="info" class="searchBtnLog" data-id="1" style="width: 144px; display: block; margin: 0 auto"
				@click="getData(1, 10)">查询</Button>
		</div>
		<div class="topPart" v-if="tab == 'tab3'" style="height: 250px">
			<div class="form-item">
				<div class="inputBox">
					<div class="title">债券名称</div>
					<Input v-model="params2.zqmc" placeholder="模糊搜索" style="margin-right: 50px; width: 250px" />
				</div>
				<div class="inputBox">
					<div class="title">发行人</div>
					<Input v-model="params2.fxr" placeholder="模糊搜索" style="margin-right: 50px; width: 250px" />
				</div>
				<div class="inputBox">
					<div class="title">承销商/管理人</div>
					<Input v-model="params2.cxsGlr" placeholder="模糊搜索" style="width: 250px" />
				</div>
			</div>
			<div class="form-item">
				<div class="title">债券品种</div>
				<Select v-model="params2.zqpz" clearable style="width: 200px; margin-right: 20px">
					<Option v-for="item in xmjdZqpzList" :value="item.name" :key="item.name">{{ item.name }}</Option>
				</Select>
				<div class="title">项目状态</div>
				<Select v-model="params2.xmzt" clearable style="width: 200px; margin-right: 20px">
					<Option v-for="item in xmjdXmztList" :value="item.name" :key="item.name">{{ item.name }}</Option>
				</Select>
				<div class="title">行业类别</div>
				<Select v-model="params2.xyfl" clearable style="width: 200px; margin-right: 20px">
					<Option v-for="item in xmjdHylxList" :value="item.name" :key="item.name">{{ item.name }}</Option>
				</Select>
				<div class="title">发行金额</div>
				<Select v-model="params2.nfxje" clearable style="width: 200px; margin-right: 20px">
					<Option v-for="item in fxjeList" :value="item.name" :key="item.name">{{ item.name }}</Option>
				</Select>
			</div>
			<div class="form-item">
				<div class="title">地区</div>
				<Select v-model="params2.dq" clearable style="width: 200px; margin-right: 20px">
					<Option v-for="item in dqList" :value="item.name" :key="item.name">{{
						item.name
					}}</Option>
				</Select>
				<span class="title">更新日期</span>
				<DatePicker :type="'date'" v-model="params2.gxrq[0]" @on-change="getTimeStartgx" format="yyyy-MM-dd"
					placeholder="开始时间" style="width: 140px; margin-right: 5px"></DatePicker>至
				<DatePicker :type="'date'" v-model="params2.gxrq[1]" @on-change="getTimeEndgx" format="yyyy-MM-dd"
					placeholder="结束时间" style="width: 140px; margin: 0 50px 0 5px"></DatePicker>
				<div class="title">发行市场</div>
				<CheckboxGroup v-model="params2.fxsc">
					<Checkbox :label="item.name" v-for="(item, index) in xmjdSjlyList" :key="index"></Checkbox>
				</CheckboxGroup>
			</div>
			<Button class="searchBtnLog" data-id="1" type="info" style="width: 144px; display: block; margin: 0 auto"
				@click="getData(1, 10)">查询</Button>
		</div>
		<div class="middlePart" v-if="tab != 'tab3'">
			<span>已选条件：</span>
			<Tag type="border" v-if="params.zqqc" size="large" @on-close="params.zqqc = ''">
				债券全称:
				<span class="tabContent">{{ params.zqqc }}</span>
			</Tag>
			<Tag type="border" v-if="params.zqjc" size="large" @on-close="params.zqjc = ''">
				债券简称:
				<span class="tabContent">{{ params.zqjc }}</span>
			</Tag>
			<Tag type="border" v-if="params.zqsc.length" size="large" @on-close="params.zqsc = []">
				债券市场:
				<span class="tabContent">{{ params.zqsc.join(",") }}</span>
			</Tag>
			<Tag type="border" v-if="params.zqlx" size="large" @on-close="params.zqlx = ''">
				债券大分类:
				<span class="tabContent">{{
					zqlxzdhList.filter((item) => item.id == params.zqlx)[0].name
				}}</span>
			</Tag>
			<Tag type="border" v-if="params.zqlxy" size="large" @on-close="params.zqlxy = ''">
				债券细分类型:
				<span class="tabContent">{{
					zqlxList.filter((item) => item.id == params.zqlxy)[0].name
				}}</span>
			</Tag>
			<Tag type="border" v-if="params.stjg" size="large" @on-close="params.stjg = ''">
				发行人 / 受托机构:
				<span class="tabContent">{{ params.stjg }}</span>
			</Tag>
			<Tag type="border" v-if="params.ysqyr" size="large" @on-close="params.ysqyr = ''">
				原始权益人:
				<span class="tabContent">{{ params.ysqyr }}</span>
			</Tag>
			<Tag type="border" v-if="params.fxrq[0] || params.fxrq[1]" size="large" @on-close="closeTime1">
				发行日期:
				<span class="tabContent">{{ formatTime(params.fxrq) }}</span>
			</Tag>
			<Tag type="border" v-if="params.qxrq[0] || params.qxrq[1]" size="large" @on-close="closeTime2">
				起息日期:
				<span class="tabContent">{{ formatTime(params.qxrq) }}</span>
			</Tag>
			<Tag type="border" v-if="params.dqrq[0] || params.dqrq[1]" size="large" @on-close="params.dqrq = []">
				到期日期:
				<span class="tabContent">{{ formatTime(params.dqrq) }}</span>
			</Tag>
			<Tag type="border" v-if="params.dqqk[0]" size="large" @on-close="params.dqqk = ['']">
				剩余期限:
				<span class="tabContent">{{
					syqxList.find((item) => item.id == params.dqqk[0]).name
				}}</span>
			</Tag>
			<Tag type="border" v-if="params.wyqk" size="large" @on-close="params.wyqk = ''">
				是否违约:
				<span class="tabContent">{{
					params.wyqk == "true" ? "是" : "否"
				}}</span>
			</Tag>
			<Tag type="border" v-if="params.hyhf" size="large" @on-close="params.hyhf = ''">
				行业分类:
				<span class="tabContent">{{
					categoryList.find((item) => item.code == params.hyhf).name
				}}</span>
				<!-- <span class="tabContent">{{  params.hyhf}}</span> -->
			</Tag>
		</div>



		<div class="middlePart" v-if="tab == 'tab3'">
			<span>已选条件：</span>
			<Tag type="border" v-if="params2.zqmc" size="large" @on-close="params2.zqmc = ''">
				债券名称:
				<span class="tabContent">{{ params2.zqmc }}</span>
			</Tag>
			<Tag type="border" v-if="params2.fxr" size="large" @on-close="params2.fxr = ''">
				发行人:
				<span class="tabContent">{{ params2.fxr }}</span>
			</Tag>
			<Tag type="border" v-if="params2.cxsGlr" size="large" @on-close="params2.cxsGlr = ''">
				承销商 / 管理人:
				<span class="tabContent">{{ params2.cxsGlr }}</span>
			</Tag>
			<Tag type="border" v-if="params2.zqpz" size="large" @on-close="params2.zqpz = ''">
				债券品种:
				<span class="tabContent">{{ params2.zqpz }}</span>
			</Tag>
			<Tag type="border" v-if="params2.xmzt" size="large" @on-close="params2.xmzt = ''">
				项目状态:
				<span class="tabContent">{{ params2.xmzt }}</span>
			</Tag>
			<Tag type="border" v-if="params2.xyfl" size="large" @on-close="params2.xyfl = ''">
				行业类别:
				<span class="tabContent">{{ params2.xyfl }}</span>
			</Tag>
			<Tag type="border" v-if="params2.dq" size="large" @on-close="params2.dq = ''">
				地区:
				<span class="tabContent">{{ params2.dq }}</span>
			</Tag>
			<Tag type="border" v-if="params2.nfxje" size="large" @on-close="params2.nfxje = ''">
				发行金额:
				<span class="tabContent">{{ params2.nfxje }}</span>
			</Tag>
			<Tag type="border" v-if="params2.gxrq[0] || params2.gxrq[1]" size="large" @on-close="params2.gxrq = []">
				更新日期:
				<span class="tabContent">{{ formatTime(params2.gxrq) }}</span>
			</Tag>
			<Tag type="border" v-if="params2.fxsc.length" size="large" @on-close="params2.fxsc = []">
				发行市场:
				<span class="tabContent">{{ params2.fxsc.join(",") }}</span>
			</Tag>
		</div>
		<div class="bottomPart">
			<DownloadBtn :id="5" :type=1
				:style="{ position: 'relative', left: 0, 'margin-bottom': '20px', 'background-color': 'rgba(241, 110, 113, 1)', }"
				@exportData="exportData" v-if="tab == 'tab1'"></DownloadBtn>
			<Table border :loading="tableLoading" tooltip-theme="light" :columns="stockColumns" :data="stockTable">
				<template slot-scope="{ row }" slot="zqjc">
					<Tooltip v-if="row.isHalfYear" content="债券半年内到期" theme="light" transfer>
						<span class="icon ban">半</span>
					</Tooltip>
					<Tooltip v-if="row.isWy == '1'" content="该债券已违约" theme="light" transfer>
						<span class="icon wei">违</span>
					</Tooltip>
					<a class="linkBtn" :title="row.zqjc" @click="geDetail(row)">
						{{ row.zqjc }}
					</a>
				</template>
			</Table>
			<div style="text-align: right; padding: 20px">
				<Page :current='params.current' :total="total" show-elevator @on-change="pageChange" />
			</div>
		</div>
	</div>
</template>

<script>
import DownloadBtn from "@/components/DownloadBtn";
import DateRange from "./components/boneDateRange"
import {
	downloadData,
	formatJson
} from "@/utils/index";
import {
	mapState
} from "vuex";
import {
	returnFloat
} from "@/utils";

// @ is an alias to /src
import {
	queryJnz,
	queryXmjd,
	xmjdSjly,
	xmjdXmzt,
	xmjdZqpz,
	xmjdHylx,
	zqlxzdh,
	zqlx,
	fxje,
	dq,
	getSyqx,
	getJnzhy,
} from "@/api/financial/debt";
import {
	getQy
} from "@/api/public";
import dataReports from "@/mixins/dataReports";
import tagBoxMixin from '@/views/share/tagBoxMixin'
export default {
	name: "domesticDebt",
	mixins: [dataReports, tagBoxMixin],
	components: {
		DownloadBtn,
		DateRange
	},
	data() {
		return {
			tab: "tab1",
			params: {
				current: 1,
				dqqk: [""],
				fxrq: [],
				hyhf: "",
				zqsc: [],
				qxrq: [],
				size: 10,
				sjly: [],
				dqrq: [],
				sjrzr: "",
				stjg: "",
				wyqk: "",
				ysqyr: "",
				zqdm: "",
				zqjc: "",
				zqlx: "",
				zqlxy: "",
				zqqc: "",
			},
			params2: {
				current: 1,
				fxr: "",
				gxrq: [],
				jysqrwjwh: "",
				size: 10,
				sjly: [],
				zqmc: "",
				zqpz: "",
				xyfl: "",
				xmzt: "",
				cxsGlr: "",
				nfxje: "",
				fxsc: [],
				dq: "",
			},
			zqlxList: [],
			syqxList: [],
			typeList: [],
			smallTypeList: [],
			categoryList: [],
			category: "",
			breakList: [{
				value: "true",
				label: "是",
			},
			{
				value: "false",
				label: "否",
			},
			],
			stockTable: [],
			pickerOptions: {
				disabledDate: (time) => {
					// var sdate = new Date(Date.parse(this.params.fxrq[0].replace(/-/g, "/")));
					return false;
				},
			},
			stockColumns3: [{
				title: "债券名称",
				key: "zqmc",
				align: "center",
				width: 340,
				ellipsis: true,
				tooltip: true,
				render: (h, params) => {
					return h(
						"a", {
						attrs: {
							class: "btn-detail",
							title: params.row.zqmc,
						},
						on: {
							click: () => {
								let path =
									this.tab == "tab1" ?
										`/Insight/financialMarket/financialProduct/Bond/domesticDetail?tab=1` :
										`/Insight/financialMarket/financialProduct/Bond/domesticDetail?tab=3`;
								// this.$router.push({
								//     path: path,
								//     query: {
								//         id: params.row.id
								//     }
								// });
								let routeData = this.$router.resolve({
									path: path,
									query: {
										id: params.row.id,
									},
								});
								window.open(routeData.href, "_blank");
							},
						},
					},
						params.row.zqmc
					);
				},
			},
			{
				title: "承销商/管理人",
				key: "cxsGlr",
				align: "center",
				width: 340,
				ellipsis: true,
				tooltip: true,

				render: (h, params) => {
					return h(
						"a", {
						attrs: {
							class: "btn-detail",
							title: params.row.cxsGlr,
						},
						on: {
							click: () => {
								getQy({
									code: params.row.cxsGlr,
								}).then((res) => {
									if (res.type == 1) {
										let routeUrl = this.$router.resolve({
											path: `/Insight/enterprise/enterpriseHome/reHouseEnterprise/information`,
											query: {
												code: res.cxsGlr,
											},
										});
										window.open(routeUrl.href, "_blank");
									} else if (res.type == 2) {
										let routeUrl = this.$router.resolve({
											path: `/Insight/enterprise/bank/bankInformation`,
											query: {
												code: res.cxsGlr,
											},
										});
										window.open(routeUrl.href, "_blank");
									} else {
										// this.$Message.warning("未匹配到企业");
										this.$msg.error({
											text: "未匹配到企业"
										});
									}
								});
							},
						},
					},
						params.row.fxr
					);
				},
			},
			{
				title: "债券品种",
				align: "center",
				ellipsis: true,
				tooltip: true,

				key: "zqpz",
			},
			{
				title: "拟发行金额（亿元）",
				ellipsis: true,
				tooltip: true,

				renderHeader: (h, index) => {
					return h("div", [h("div", "拟发行金额"), h("div", "（亿元）")]);
				},
				align: "center",
				key: "nfxje",
			},
			{
				title: "项目状态",
				align: "center",
				ellipsis: true,
				tooltip: true,
				key: "xmzt",
			},
			{
				title: "更新日期",
				align: "center",
				ellipsis: true,
				tooltip: true,

				key: "gxrq",
			},
			],
			tableLoading: false,
			stockColumns1: [{
				title: "债券简称",
				key: "zqjc",
				align: "center",
				width: 200,
				ellipsis: true,
				tooltip: true,
				slot: 'zqjc',

			},
			{
				title: "债券代码",
				key: "zqdm",
				align: "center",
				width: 150,
				ellipsis: true,
				tooltip: true,

				render: (h, params) => {
					return h(
						"a", {
						attrs: {
							class: "btn-detail",
							title: params.row.zqdm,
						},
						on: {
							click: () => {
								let path =
									this.tab == "tab1" ?
										`/Insight/financialMarket/financialProduct/Bond/domesticDetail?tab=1` :
										`/Insight/financialMarket/financialProduct/Bond/domesticDetail?tab=3`;
								// this.$router.push({
								//     path: path,
								//     query: {
								//         id: params.row.id
								//     }
								// });
								let routeData = this.$router.resolve({
									path: path,
									query: {
										id: params.row.id,
									},
								});
								window.open(routeData.href, "_blank");
							},
						},
					},
						params.row.zqdm
					);
				},
			},
			{
				title: "发行日期",
				align: "center",
				ellipsis: true,
				tooltip: true,

				width: 150,
				key: "fxrq",
			},
			{
				title: "到期日期",
				align: "center",
				width: 150,
				ellipsis: true,
				tooltip: true,

				key: "dqrq",
			},
			{
				title: "计划发行总额 （亿元）",
				width: 130,
				ellipsis: true,
				tooltip: true,

				renderHeader: (h, index) => {
					return h("div", [h("div", "计划发行总额"), h("div", "（亿元）")]);
				},
				align: "center",
				key: "jhfxze",
				render: (h, params) => {
					return h(
						"span",
						params.row.jhfxze ? !isNaN(params.row.jhfxze) ? Number(params.row.jhfxze)
							.toFixed(2) : params.row.jhfxze : params.row.jhfxze
					);
				},
			},
			{
				title: "实际发行总额 （亿元）",
				width: 130,
				ellipsis: true,
				tooltip: true,

				renderHeader: (h, index) => {
					return h("div", [h("div", "实际发行总额"), h("div", "（亿元）")]);
				},
				align: "center",
				key: "sjfxze",
				render: (h, params) => {
					return h(
						"span",
						params.row.sjfxze ? !isNaN(params.row.sjfxze) ? Number(params.row.sjfxze)
							.toFixed(2) : params.row.sjfxze : params.row.sjfxze
					);
				},
			},
			{
				title: "票面利率 （%）",
				ellipsis: true,
				width: 150,
				tooltip: true,

				renderHeader: (h, index) => {
					return h("div", [h("div", "票面利率"), h("div", "（%）")]);
				},
				align: "center",
				key: "pmll",
				render: (h, params) => {
					return h(
						"span",
						params.row.pmll ? !isNaN(params.row.pmll) ? Number(params.row.pmll).toFixed(
							2) : params.row.pmll : params.row.pmll
					);
				},
			},
			{
				title: "债券类型",
				align: "center",
				ellipsis: true,
				tooltip: true,

				width: 150,
				key: "zqlx",
			},
			{
				title: "发行人/受托机构",
				key: "fxr",
				align: "center",
				width: 300,
				ellipsis: true,
				tooltip: true,

				render: (h, params) => {
					return h(
						"a", {
						attrs: {
							class: "btn-detail",
							title: params.row.fxr,
						},
						on: {
							click: () => {
								getQy({
									code: params.row.fxr,
								}).then((res) => {
									if (res.type == 1) {
										this.$router.push({
											path: '/Insight/enterprise/enterpriseHome/reHouseEnterprise/information',
											query: {
												code: res.code,
												isMarket: res.isMarket
											}
										})
									} else if (res.type == 2) {
										this.$router.push({
											path: '/Insight/enterprise/bank/bankInformation',
											query: {
												code: res.code,
												isMarket: res.isMarket
											},
										})
									} else {
										// this.$Message.warning("未匹配到企业");
										this.$msg.error({
											text: "未匹配到企业"
										});
									}
								});
							},
						},
					},
						params.row.fxr
					);
				},
			},
			{
				title: "原始权益人",
				align: "center",
				width: 300,
				ellipsis: true,
				tooltip: true,
				key: "ysqyr",
			},
			// {
			//     title: '预期收益率 （%）',
			//     align: 'center',
			//     key: 'yqsyl'
			// },
			{
				title: "债券期限",
				align: "center",
				width: 150,
				ellipsis: true,
				tooltip: true,

				key: "zqqx",
			},

			],
			zqlxzdhList: [],
			total: 0,
			xmjdSjlyList: [],
			xmjdXmztList: [],
			xmjdZqpzList: [],
			xmjdHylxList: [],
			dqList: [],
			fxjeList: [],
		};
	},
	computed: {
		...mapState(["zqscList"]),
		stockColumns() {
			switch (this.tab) {
				case "tab1":
					this.getData(1, 10);
					return this.stockColumns1;
				case "tab2":
					return this.stockColumns1;
				case "tab3":
					this.getData(1, 10);
					return this.stockColumns3;
				default:
					return this.stockColumns1;
			}
		},
	},
	mounted() {
		this.getData(1, 10);
		this._xmjdHylx();
		// this._xmjdFxje()
		this._xmjdSjly();
		this._xmjdXmzt();
		this._xmjdZqpz();
		this._zqlxzdh();
		this.fxje();
		this.getSyqx();
		this.dq();
		this.getJnzhy();
	},

	methods: {
		getTimeStartqx(value) {
			this.$set(this.params.qxrq, 0, value);
		},
		getTimeEndqx(value) {
			this.$set(this.params.qxrq, 1, value);
		},
		getTimeStartfx(value) {
			this.$set(this.params.fxrq, 0, value);
		},
		getTimeEndfx(value) {
			this.$set(this.params.fxrq, 1, value);
		},
		getTimeStartdq(value) {
			this.$set(this.params.dqrq, 0, value);
		},
		getTimeEnddq(value) {
			this.$set(this.params.dqrq, 1, value);
		},
		getTimeStartgx(value) {
			this.$set(this.params2.gxrq, 0, value);
		},
		getTimeEndgx(value) {
			this.$set(this.params2.gxrq, 1, value);
		},
		_xmjdHylx() {
			xmjdHylx().then((data) => {
				this.xmjdHylxList = data;
			});
		},
		getJnzhy() {
			getJnzhy().then((res) => {
				this.categoryList = res;
			});
		},
		getzqlx(data) {
			if (!data) return;
			zqlx({
				pid: data,
			}).then((res) => {
				this.zqlxList = res;
				this.params.zqlxy = "";
			});
		},
		getSyqx() {
			getSyqx().then((res) => {
				this.syqxList = res;
				this.params.dqqk = [""];
			});
		},
		_zqlxzdh() {
			zqlxzdh().then((data) => {
				this.zqlxzdhList = data;
			});
		},
		fxje() {
			fxje().then((data) => {
				this.fxjeList = data;
			});
		},
		dq() {
			dq().then((data) => {
				this.dqList = data;
			});
		},

		_xmjdSjly() {
			xmjdSjly().then((data) => {
				this.xmjdSjlyList = data;
			});
		},
		_xmjdXmzt() {
			xmjdXmzt().then((data) => {
				this.xmjdXmztList = data;
			});
		},
		_xmjdZqpz() {
			xmjdZqpz().then((data) => {
				this.xmjdZqpzList = data;
			});
		},
		closeTime1() {
			this.params.fxrq = [];
		},
		closeTime2() {
			this.params.qxrq = [];
		},
		pageChange(current) {
			switch (this.tab) {
				case "tab1":
					this.getData(current, 10);
					break;
				case "tab2":
					this.params2.current = current;
					break;
				case "tab3":
					this.getData(current, 10);
					break;
				default:
					return this.stockColumns1;
			}
		},
		xmjd(current, size) {
			return new Promise((res, rej) => {
				this.tableLoading = true
				this.stockTable = []
				if (size) {
					this.params2.size = size
				}
				this.params2.current = current
				queryXmjd(this.params2)
					.then((data) => {
						res(data)
					}).catch(err => {
						rej(err)
					})
					.finally(() => {
						this.tableLoading = false;
					});
			})

		},
		jnz(current, size) {
			return new Promise((res, rej) => {
				this.params.current = current
				if (size) {
					this.params.size = size
				}
				queryJnz(this.params)
					.then((data) => {
						res(data)
					}).catch(err => {
						rej(err)
					})
					.finally(() => {
						this.tableLoading = false;
					});
			})

		},
		async getData(current, size) {
			this.tableLoading = true;
			// this.stockTable = []
			let data
			if (this.tab == 'tab1') {
				data = await this.jnz(current, size)
			} else if (this.tab == 'tab3') {
				data = await this.xmjd(current, size)
			}
			this.stockTable = data.records;
			this.total = data.total;
			this.params.current = data.current
		},
		tableClick(row) {
			let path =
				this.tab == "tab1" ?
					`/Insight/financialMarket/financialProduct/Bond/domesticDetail?tab=1` :
					`/Insight/financialMarket/financialProduct/Bond/domesticDetail?tab=3`;
			this.$router.push({
				path: path,
				query: {
					id: row.id,
				},
			});
		},
		formatTime(time) {
			if (time[0] && !time[1]) {
				return `>= ${time[0]}`
			} else if (!time[0] && time[1]) {
				return `<=${time[1]}`
			} else if (time[0] && time[1]) {
				return `${time[0]}至${time[1]}`
			}
		},
		geDetail(row) {
			let path =
				this.tab == "tab1" ?
					`/Insight/financialMarket/financialProduct/Bond/domesticDetail?tab=1` :
					`/Insight/financialMarket/financialProduct/Bond/domesticDetail?tab=3`;
			let routeData = this.$router.resolve({
				path: path,
				query: {
					id: row.id,
				},
			})
			window.open(routeData.href, "_blank");
		},
		async exportData() {
			let data
			let filterVal = []
			let tHeader = []
			if (this.tab == 'tab1') {
				data = await this.jnz(1, 999)
			} else if (this.tab == 'tab3') {
				data = await this.xmjd(1, 999)
			}
			this.stockColumns.forEach((item) => {
				tHeader.push(item.title)
				filterVal.push(item.key)
			})
			const resData = formatJson(filterVal, data.records);
			downloadData(tHeader, resData, "境内债")
		},
	}
}
</script>
<style lang="scss" scoped>
@import "@/style/common.scss";

.domesticDebt {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	background: #fff;
	padding: 20px;
}

.domesticDebt {
	.tabBox {
		@include flex(flex-start, flex-start);
		width: 100%;
		height: 37px;
		background: rgba(252, 252, 255, 1);
		border: 1px solid rgba(214, 215, 217, 1);
		margin-bottom: 20px;
	}

	.topPart {
		width: 100%;
		height: 400px;
		background: rgba(255, 255, 255, 1);
		border-radius: 10px;
		border: 1px solid rgba(227, 227, 229, 1);
		padding: 20px 30px;

		.form-item {
			margin-bottom: 20px;
			@include flex(flex-start);

			.inputBox {
				@include flex(flex-start);
			}

			.title {
				font-size: 14px;
				font-family: MicrosoftYaHei;
				color: rgba(102, 102, 102, 1);
				line-height: 19px;
				margin-right: 20px;
				word-break: keep-all;
			}

			.addIssue {
				position: relative;

				.seletcTypeBox {
					position: absolute;
					left: 0;
					top: 20px;
					height: 40px;
					display: none;
					overflow: hidden;
					background: #fff;
					border: 1px solid rgba(227, 227, 229, 1);
					padding: 10px;
					z-index: 100;
				}

				&:hover .seletcTypeBox {
					display: block;
				}
			}
		}
	}

	.middlePart {
		margin: 20px 0;
	}
}

.icon {
	display: inline-block;
	width: 20px;
	height: 20px;
	font-size: 14px;
	text-align: center;
	line-height: 20px;
	color: white;
	margin: 0 3px;

	&.ban {
		background-color: #F59A23;
	}

	&.wei {
		background-color: #D9001B;
	}
}

.linkBtn {
	width: 120px;
	display: inline-block;
	text-align: center;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	position: relative;
	top: 7px;
}
</style>
