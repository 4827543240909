<template>
  <div class="warp">
    <DatePicker
      :type=dataRType
      v-model="startTime"
      format="yyyy-MM-dd"
      :options="startDateOptions"
      @on-change="handleChangeStartime"
      placeholder="开始时间"
      style="max-width: 140px; margin-right: 5px"
    ></DatePicker
    >至
    <DatePicker
      :type="dataRType"
      v-model="endTime"
      format="yyyy-MM-dd"
      :options="endDateOptions"
      @on-change="handleChangeEndtime"
      placeholder="结束时间"
      style="max-width: 140px; margin: 0 50px 0 5px"
    ></DatePicker>
  </div>
</template>
<script>
import { log } from "../../../utils";
export default {
  name: "boneDateRange",
  model: {
    prop: "time",
    event: "change",
  },
  props: {
    time: Array,
    timeNum: Number,
    dateType: {
      type: String,
      default: "date",
    },
  },
  data() {
    return {
      startTime: "",
      endTime: "",
      quarterStart: "",
      quarterEnd: "",
      quarterList: ["Q1", "Q2", "Q3", "Q4"],
      dataRType: "date",
      startDateOptions: {},
      endDateOptions: {},
    };
  },
  watch: {
    dateType(newValue) {
      if (newValue == "jd") {
        this.dataRType = "year";
      } else {
        this.dataRType = newValue;
      }
    },
    time(newValue) {
      this.startTime = JSON.parse(JSON.stringify(this.time[0]));
      this.endTime = JSON.parse(JSON.stringify(this.time[1]));
    },
  },
  mounted() {
    // this.startTime = JSON.parse(JSON.stringify(this.time[0]));
    // this.endTime = JSON.parse(JSON.stringify(this.time[1]));
    if (this.dateType == "jd") {
      this.dataRType = "year";
    } else {
      this.dataRType = this.dateType;
    }
  },
  methods: {
    handleChangeStartime(value) {
      this.startTime = value;
      if (this.endTime.getFullYear) {
        if (this.dataRType == "month") {
          this.endTime = `${this.endTime.getFullYear()}${
            this.endTime.getMonth() + 1 < 10
              ? `-0${this.endTime.getMonth() + 1}`
              : `-${this.endTime.getMonth() + 1}`
          }`;
        } else if (this.dataRType == "year") {
          this.endTime = `${this.endTime.getFullYear()}`;
        } else {
          this.endTime = `${this.endTime.getFullYear()}${
            this.endTime.getMonth() + 1 < 10
              ? `-0${this.endTime.getMonth() + 1}`
              : `-${this.endTime.getMonth() + 1}`
          }${
            this.endTime.getDate() < 10
              ? `-0${this.endTime.getDate()}`
              : `-${this.endTime.getDate()}`
          }`;
        }
      }
      this.setDateDisabled()
      this.$emit("change", [`${this.startTime}`, `${this.endTime}`]);
    },
    handleChangeEndtime(value) {
      this.endTime = value;
      if (this.startTime.getFullYear) {
        if (this.dataRType == "month") {
          this.startTime = `${this.startTime.getFullYear()}${
            this.startTime.getMonth() + 1 < 10
              ? `-0${this.startTime.getMonth() + 1}`
              : `-${this.startTime.getMonth() + 1}`
          }`;
        } else if (this.dataRType == "year") {
          this.startTime = `${this.startTime.getFullYear()}`;
        } else {
          this.startTime = `${this.startTime.getFullYear()}${
            this.startTime.getMonth() + 1 < 10
              ? `-0${this.startTime.getMonth() + 1}`
              : `-${this.startTime.getMonth() + 1}`
          }${
            this.startTime.getDate() < 10
              ? `-0${this.startTime.getDate()}`
              : `-${this.startTime.getDate()}`
          }`;
        }
      }
      this.setStartDateDisabled()
      this.$emit("change", [`${this.startTime}`, `${this.endTime}`]);
    },
    setDateDisabled() {
      this.endDateOptions = {
        disabledDate: (date) => {
          let startDate = this.startTime
            ? new Date(this.startTime).valueOf()
            : "";
          return date && date.valueOf() < startDate;
        },
      };
    },
    setStartDateDisabled() {
      this.startDateOptions = {
        disabledDate: (date) => {
          let endDate = this.endTime ? new Date(this.endTime).valueOf() : {};
          return date && date.valueOf() > endDate;
        },
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.warp {
  display: flex;
  align-items: center;
  .ivu-date-picker {
    flex-shrink: 1;
  }
}
</style>